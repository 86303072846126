
























































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'templateManage' })
export default class templateManage extends VueBase {
  private templateForm: any = {
    content: [],
    fid: 1,
    name: '',
    status: 1
  }
  private strategyTypeList:any = []
  private defaultProps:any =  {
    children: 'type_value',
    label: 'vul_name'
  }
  private async strategyTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyTypes()
    this.loadingDone()
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.strategyTypeList = data.map((item: any) => {
      item.strategy_id = item.level_name
      item.vul_name = item.level_name
      item.isCollapse = item.type_value?.length > 0
      item.disabled = !!this.$route.query.view
      item.type_value = item.type_value.map((ele: any) => {
        ele.isCollapse = false
        ele.disabled = !!this.$route.query.view
        return ele
      })
      return item
    }).filter((item: any) => item.isCollapse)
    // console.log('this.strategyTypeList', this.strategyTypeList)
  }

  private rules = {
    name: [
      {
        required: true,
        message: this.$t('views.templateManage.nameR') as string,
        trigger: 'blur',
      },
    ],
  }

  async templateAdd() {
    // 新增确认
    this.loadingStart()
    let ids = ((this.$refs.tree as any)?.getCheckedKeys() || []).filter((item:any) => typeof item === 'number')
    const res = await this.services.setting.create_scan_strategy({
      ...this.templateForm,
      content: ids
    })
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success('新增策略模板成功')
      this.goBack()
    } else {
      this.$message.error('新增策略模板失败，请重试')
    }
  }
  async templateEdit() {
    // 编辑确认
    this.loadingStart()
    let ids = ((this.$refs.tree as any)?.getCheckedKeys() || []).filter((item:any) => typeof item === 'number')
    const res = await this.services.setting.update_scan_strategy({
      ...this.templateForm,
      content: ids
    })
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success('策略模板编辑成功')
      this.goBack()
    } else {
      this.$message.error('策略模板编辑失败，请重试')
    }
  }
  async templateDialogEdit() {
    // 编辑获取数据
    const res = await this.services.setting.get_scan_strategy_one({
      id: this.$route.query.id,
    })
    if (res.status === 201) {
      this.templateForm.id = res.data.id
      this.templateForm.name = res.data.name
      ;(this.$refs.tree as any)?.setCheckedKeys(res.data.content||[])
      this.templateForm.status = res.data.status
    } else {
      this.$message.error(res.msg)
    }
  }
  templateDialogEnter() {
    const form: any = this.$refs.ruleForm
    form.validate((valid: boolean) => {
      if (valid) {
        if (this.$route.query.id) {
          this.templateEdit()
        } else {
          this.templateAdd()
        }
      }
    })
  }
  async goBack() {
    this.$router.push({ name: 'settingTemplate' })
  }
  async created() {
    this.loadingStart()
    await this.strategyTypes()
    if (this.$route.query.id) {
      await this.templateDialogEdit()
    }
    this.loadingDone()
  }
}
